import { API, graphqlOperation } from 'aws-amplify';
import { cloneDeep }             from 'lodash';

import { listEmails } from '../../graphql/queries/Email.query';

import {
  SEARCH_FOR_INBOX,
  UPDATE_INBOX_FIELD
} from '../reducers/inbox';

export const searchForInbox = () => async (dispatch, getState) => {
  const { inbox } = cloneDeep(getState());

  let graphQLResponse = await API.graphql(graphqlOperation(listEmails, { input: { inboxId: inbox.searchInput.value, limit: 1000 } }));

  inbox.emails = graphQLResponse.data.listEmails;
  inbox.lastSearched = inbox.searchInput.value;
  
  dispatch({
    type: SEARCH_FOR_INBOX,
    payload: inbox
  });

  return inbox;
};

export const updateField = (field, value) => (dispatch, getState) => {
  const { inbox } = cloneDeep(getState());

  inbox[field].value = value;

  dispatch({
    type: UPDATE_INBOX_FIELD,
    payload: inbox
  });
};
