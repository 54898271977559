import { emailParams } from '../parameters';

export const getEmail = `
  query getEmail($pk: ID!, $sk: ID!) {
    getEmail(pk: $pk, sk: $sk) {
      ${emailParams}
    }
  }
`;

export const listEmails = `
  query ListEmails($input: ListEmailsInput!) {
    listEmails(input: $input) {
      pk
      sk
      subject
      from {
        address
        name
      }
      date
    }
  }
`;