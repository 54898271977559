const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        color: 'white'
    },
    loginButton: {
        marginBottom: 24,
        marginTop: 24,
    },
    bottomLinks: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    bottomLink: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontSize: 14
    }
});

export default styles;