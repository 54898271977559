import React, { useState }    from 'react';
import { withStyles }         from '@material-ui/core/styles';
import { connect }            from 'react-redux';
import { Auth }               from 'aws-amplify'

import { validateForm } from '../../../modules/validate-form';
import { setUser }      from '../../../redux/actions/user';

import Checkbox         from '@material-ui/core/Checkbox';
import Grid             from '@material-ui/core/Grid';

import AuthHeader       from '../../../components/AuthHeader';
import AuthForm         from '../../../components/AuthForm/AuthForm';
import Button           from '../../../components/AuthButton';
import Input            from '../../../components/AuthInput';

import styles from './styles';

const Login = (props) => {
  const { classes, setCognitoUser, setUser } = props;

  const [email, setEmail] = useState({value: '', valid: true});
  const [loginPassword, setLoginPassword] = useState({value: '', valid: true});
  const [rememberMeChecked, setRememberMeChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  function handleError(error) {
    console.error(error);
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  async function handleLogin(e) {
    e.preventDefault();

    const formObject = { email, loginPassword };
    const formValid = validateForm(formObject);

    if(formValid.invalid.length > 0) {
      return handleError(new Error(formValid.invalid[0].message));
    }

    setLoading(true);
    try {
      let cognitoUser = await Auth.signIn(email.value.toLowerCase(), loginPassword.value)

      if (!rememberMeChecked) {
        window.addEventListener('beforeunload', () => {
          Auth.signOut();
          setUser();
        });
      }

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(cognitoUser);
        props.history.replace('/new-password');
        return;
      }
      
      setUser(cognitoUser);
    }
    catch(error) {
      if (error.code === 'PasswordResetRequiredException') {
        try {
          await Auth.forgotPassword(email.value.toLowerCase())
          setLoading(false);
          props.history.push(`/reset-password?email=${email.value.toLowerCase()}`);
        } catch (forgotPasswordError) {
          setLoading(false);
          handleError(forgotPasswordError);
        }
      } 
      else if(error.code === "UserNotConfirmedException") {
        setLoading(false);
        props.history.push(`/confirm?email=${email.value.toLowerCase()}`);
      } 
      else {
        setLoading(false);
        handleError(error);
      }
    }
  }

  return (
    <AuthForm onSubmit={handleLogin}>

      <AuthHeader />

      <Input
        id='email'
        marginBottom={16}
        label='Email Address'
        onChange={e => setEmail({ value: e.target.value, valid: true })}
        value={email.value}
        error={!email.valid} />
      <Input
        id='loginPassword'
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setLoginPassword({ value: e.target.value, valid: true })}
        value={loginPassword.value}
        error={!loginPassword.valid} />
      <Grid container spacing={0} alignItems="center" justify="flex-start">
        <span className={classes.label}>Remember me?</span>
        <Checkbox
          checked={rememberMeChecked}
          onChange={(e) => setRememberMeChecked(e.target.checked)}
          value="remember"
          className="remember-me-checkbox"
          color="secondary"
        />
      </Grid>

      <input type="submit" style={{ display: 'none' }} />

      <Button
        className={classes.loginButton}
        loading={loading}
        onClick={handleLogin}
        text={"Login"}
        color="primary"
        size="large"
        disabled={loading}
        variant="contained"
        fullWidth={true}
      />

      <div className={classes.bottomLinks}>
        <Button className={classes.bottomLink} text="Forgot Password" onClick={() => props.history.push("/forgot-password")} size="large" />
        {/* <Button className={classes.bottomLink} text="Register New Account" onClick={() => props.history.push("/register")} /> */}
      </div>

    </AuthForm>
  );
}

export default connect(null, { setUser })(withStyles(styles)(Login));