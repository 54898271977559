import { 
  INIT,
  SET_USER 
} from '../reducers/user';

export const setUser = user => {
  if (user) {
    return {
      type: SET_USER,
      payload: user
    };
  }

  // Empty user in store if no user is sent
  return {
    type: SET_USER,
    payload: INIT
  };
}
