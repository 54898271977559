import React, { useState } from 'react';
import { Auth }             from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';


import { validateForm }  from '../../../modules/validate-form';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/AuthButton';
import Input      from '../../../components/AuthInput';

import styles from './styles';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState({value: '', valid: true});
  const [loading, setLoading] = useState(false);

  async function handleForgotPassword(e) {
    e.preventDefault();

    const formObject = { email };
    const formValid = validateForm(formObject);

    if(formValid.invalid.length > 0) {
      return handleError(new Error(formValid.invalid[0].message))
    }

    setLoading(true);

    try {
      await Auth.forgotPassword(email.value)
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      props.history.push(`/reset-password?email=${email.value}`);
    } catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm onSubmit={handleForgotPassword}>

      <AuthHeader />
        
      <Input
        id='email'
        marginBottom={24}
        label='Email Address'
        onChange={e => setEmail({value: e.target.value, valid: true})}
        value={email.value}
        error={!email.valid}
      />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        disabled={loading}
        fullWidth={true}
        onClick={handleForgotPassword}
        loading={loading}
        text={'Request Password Reset'}
        size="large"
        variant="contained"
      />

    </AuthForm>
  );
}

export default withStyles(styles)(ForgotPassword);