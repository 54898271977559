import Amplify, { API } from 'aws-amplify';

import aws_config_dev    from './awsmobile.dev';
import aws_config_master from './awsmobile.master';

let AWS_CONFIG;

switch (process.env.REACT_APP_ENV) {
  case 'master':
    AWS_CONFIG = aws_config_master;
    break;
  case 'dev':
    AWS_CONFIG = aws_config_dev;
    break;
  default:
    AWS_CONFIG = aws_config_dev;
}

Amplify.configure(AWS_CONFIG);
API.configure(AWS_CONFIG);