import React, { useState, useEffect } from 'react';
import { connect }                    from 'react-redux';
import { withRouter }                 from 'react-router';
import { Auth }                       from 'aws-amplify';

// Redux
import { setUser } from '../../redux/actions/user';
import { searchForInbox, updateField } from '../../redux/actions/inbox';
import { setSnackbar } from '../../redux/actions/snackbar';

// Material UI Core
import { withStyles }   from '@material-ui/core/styles';
import AppBar           from '@material-ui/core/AppBar';
import Drawer           from '@material-ui/core/Drawer';
import Toolbar          from '@material-ui/core/Toolbar';
import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemIcon     from '@material-ui/core/ListItemIcon';
import ListItemText     from '@material-ui/core/ListItemText';
import IconButton       from '@material-ui/core/IconButton';
import InputBase        from '@material-ui/core/InputBase';
import Hidden           from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material Icons
import ExitToAppIcon     from '@material-ui/icons/ExitToApp';
import MailIcon          from '@material-ui/icons/Mail';
import MenuIcon          from '@material-ui/icons/Menu';
import SettingsIcon      from '@material-ui/icons/Settings';
import ArrowForwardIcon  from '@material-ui/icons/ArrowForward';

import CompanyLogo from '../../assets/logo.png';

import styles from './styles';

let pollingInterval;

const Navigation = (props) => {
  const { classes, inbox, match, setSnackbar, searchForInbox, theme, updateField } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [polling, setPolling] = useState(false);

  useEffect(() => {
    
    if (typeof match.params.email === 'undefined') {
      updateField('searchInput', '');
      search();

      if (polling) {
        clearInterval(pollingInterval);
        setPolling(false);
      }
    }

    if (match.params.email) {
      updateField('searchInput', match.params.email);
      search();

      if (!polling) {
        pollingInterval = setInterval(search, 30000); // Every 30 seconds
        setPolling(true);
      }
    }

    return () => {
      if (polling) clearInterval(pollingInterval); console.log('polling stopped');
    };
  }, [match.params.email]);

  async function search() {
    setSearching(true);

    try {
      await searchForInbox();
    }
    catch (err) {
      if (typeof err === 'string') {
        setSnackbar(err);
      }
      else if (err && typeof Array.isArray(err.errors)) {
        err.errors.forEach(error => {
          setSnackbar(error.message);
        });
      }
      else if (err && err.message) {
        setSnackbar(err.message);
      }
    }
    
    setSearching(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    props.setUser();
    props.history.replace('/');
  }

  async function handleSearchSubmit(e) {
    e.preventDefault();

    if (match.params.email === inbox.searchInput.value) {
      await search();
    } else {
      props.history.push(`/inbox/${inbox.searchInput.value}`);
    }
  }

  function handleListItemButtonClick(path) {
    if (mobileOpen) setMobileOpen(false);
    props.history.push(path);
  }

  const drawer = (
    <div className={classes.drawer}>
      <div className={classes.toolbar}>
        <img src={CompanyLogo} alt="Company Logo" className={classes.logo} />
      </div>
      <List className={classes.list}>
        <ListItem button onClick={e => handleListItemButtonClick('/inbox')}>
          <ListItemIcon>
            <MailIcon style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Inbox" classes={{ primary: classes.listItemText }} />
        </ListItem>
        <ListItem button onClick={e => handleListItemButtonClick('/settings')}>
          <ListItemIcon>
            <SettingsIcon style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary="Settings" classes={{ primary: classes.listItemText }} />
        </ListItem>
        <div className={classes.spacer}></div>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" classes={{ primary: classes.listItemText }} />
          <ListItemIcon>
            <ExitToAppIcon style={{ color: 'white' }} />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>

      <AppBar className={classes.appBar} color="secondary" elevation={0}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <IconButton
            aria-label="Open drawer"
            onClick={e => setMobileOpen( !mobileOpen )}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <form onSubmit={handleSearchSubmit} className={classes.form}>

            <InputBase
              className={classes.input}
              onChange={(e) => updateField('searchInput', e.target.value)}
              placeholder="Inbox"
              value={inbox.searchInput.value}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton
              aria-label="Open drawer"
              onClick={handleSearchSubmit}
              disabled={searching}
              className={classes.iconButton}
            >
              {
                searching ?
                  <CircularProgress color="secondary" size={24} />
                  :
                  <ArrowForwardIcon />
              }
            </IconButton>
          </form>
        </Toolbar>
      </AppBar>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={e => setMobileOpen( !mobileOpen )}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            docked: classes.drawerDocked,
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        { props.children }
      </main>
      
    </div>
  );
}

const mapStateToProps = ({ inbox }) => ({ inbox });

export default withRouter(connect(mapStateToProps, { searchForInbox, setUser, updateField, setSnackbar })(withStyles(styles, { withTheme: true })(Navigation)));
