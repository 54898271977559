import { validateField } from './validate-field';


/** Global list of required fields */
let nonRequiredFields = [
]


export const validateForm = (form) => {
  const invalid = [];
  const payload = {};

  Object.keys(form).forEach((field) => {
    if(nonRequiredFields.includes(field)) return;
    
    const { value } = form[field];

    let validated = {};

    if (field === 'passwordConfirm') {
      validated = validateField(field, value, form.newPassword.value);
    } else {
      validated = validateField(field, value);
    }

    const { valid, message } = validated;

    if (!valid) {
      invalid.push({ field, message });
    }

    payload[field] = { value, valid };
  });

  return {
    invalid,
    payload,
  };
};
