// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: 'us-west-2:db6b4dad-2c18-47d3-a7e2-aff22a353026',
    userPoolId: 'us-west-2_WPxPiBCnl',
    userPoolWebClientId: '1hut2p0qdfgcjtfl5rvcq24snf',
    region: 'us-west-2',
  },
  Storage: {
    AWSS3: {
      bucket: 'fyremail-emails-production',
      region: 'us-west-2',
    },
  },
  aws_appsync_graphqlEndpoint: 'https://i4spfvqvebh3bpblpturmpfmim.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsmobile;
