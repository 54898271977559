export const ORANGE = '#FF8E00';
export const GREY = '#EDEDED';
export const DARK_GREY = '#242424';

export default {
  palette: {
    primary: {
      main: ORANGE,
      contrastText: '#ffffff'
    },
    secondary: {
      main: GREY,
      contrastText: '#ffffff'
    },
    error: {
      main: '#e50000',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: [
      'Avenir Next',
      'Arial'
    ]
  },
  mixins: {
    toolbar: {
      minHeight: 60,
      '@media (min-width: 0px) and (orientation: landscape)': {
        minHeight: 60
      },
      '@media (min-width: 600px)': {
        minHeight: 90
      }
    }
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: 22,
        fontWeight: 300
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    }
  }
};
