export const GET_EMAIL = 'GET_EMAIL';

export const INIT = {
  from: []
};

export default function(state = INIT, { type, payload }) {

  switch(type) {
    case GET_EMAIL:
      return payload;
    default:
      return state;
  }
  
}