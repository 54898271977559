import React, { useState, useEffect } from 'react';
import { Auth }             from 'aws-amplify';
import { connect }          from 'react-redux';
import { withStyles }       from '@material-ui/core/styles';

import queryString          from 'query-string';

import { setSnackbar } from '../../../redux/actions/snackbar';
import { validateForm }  from '../../../modules/validate-form';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/AuthButton';
import Input      from '../../../components/AuthInput';

import styles from './styles';
import { Typography } from '@material-ui/core';

const Confirm = (props) => {
  const { classes, setSnackbar } = props;

  const [email] = useState(queryString.parse(props.location.search).email);
  const [code, setCode] = useState({value: '', valid: true});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!email) props.history.replace('/login');
  }, [email, props.history])

  async function handleConfirm(e) {
    e.preventDefault();

    const formObject = { code };
    const formValid = validateForm(formObject);

    if (formValid.invalid.length > 0) {
      return handleError(formValid.invalid[0].message);
    }

    setLoading(true);
    try {
      await Auth.confirmSignUp(email, code.value);
      setSnackbar('Account successfully confirmed! Please login to continue.');
      props.history.push('/');
    }
    catch(error) {
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm onSubmit={handleConfirm}>

      <AuthHeader />

      <div className={classes.helpText}>
        <Typography variant="body2" component={"p"} style={{ color: 'white' }}>
          Please check your email for a verification code
        </Typography>
      </div>

      <Input
        id='code'
        marginBottom={24}
        label='Confirmation Code'
        onChange={e => setCode({value: e.target.value, valid: true})}
        value={code.value}
        error={!code.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        loading={loading}
        fullWidth={true}
        onClick={handleConfirm}
        size="large"
        text={'Confirm Account'}
        variant="contained"
      />

    </AuthForm>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { setSnackbar })(withStyles(styles)(Confirm));