import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';

import { getEmail } from '../../../redux/actions/email';

import styles from './styles';

const Email = (props) => {
  const { attachments, classes, text, subject, from, date, html, getEmail, match } = props;

  useEffect(() => {
    const { email, id } = match.params;
    getEmail(email, id);
  }, []);

  const received = new Date(date);

  return (
    <div>
      <section className={classes.info}>
        <table>
          <tbody>
            <tr>
              <td>Subject:</td>
              <td>{subject}</td>
            </tr>
            <tr>
              <td>From:</td>
              <td>{from.map(({ address }) => address).join(', ')}</td>
            </tr>
            <tr>
              <td>Received:</td>
              <td>{date && received.toString()}</td>
            </tr>
          </tbody>
        </table>
      </section>
      {
        html === 'false' ?
        <section className={classes.html}><Linkify>{text}</Linkify></section>
        :
        <section className={classes.html} dangerouslySetInnerHTML={{ __html: html === 'false' ? text : html }} />
      }
      {
        attachments && attachments.length > 0 &&
        <section className={classes.attachments}>
          {
            attachments.map(({ filename, url }, index) => <a className={classes.link} href={url} key={`attachment-${index}`}>{filename}</a>)
          }
        </section>
      }
    </div>
  );
};

const mapStateToProps = ({ email }) => ({ ...email });

export default connect(mapStateToProps, { getEmail })(withStyles(styles)(Email));