import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect }                 from 'react-redux'
import { setSnackbar }             from '../../redux/actions/snackbar'
import { withStyles }              from '@material-ui/core/styles';
import checkError                  from '../../modules/check-error';

import Navigation from '../../components/Navigation'

import Email from './Email';
import Inbox from './Inbox';

import styles from './styles';

const UserStack = props => {
    const { setSnackbar } = props;

    function handleError(error) {
        setSnackbar(checkError(error));
    }

    /** Initial route */
    const _Inbox = (props) => <Inbox onError={handleError} {...props} />;
    const _Email = (props) => <Email onError={handleError} {...props} />;

    return (
        <Navigation>
            <Switch>
                <Route path="/inbox/:email/:id" component={_Email} />
                <Route path="/inbox/:email" component={_Inbox} />
                <Redirect path="/" to="/inbox" />
            </Switch>
        </Navigation>
    );
}

const actions = { setSnackbar };
export default connect(null, actions)(withStyles(styles)(UserStack));