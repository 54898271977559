import React            from 'react';
import { withStyles }   from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

const FullscreenLoader = (props) => {
  const { classes } = props;

  return(
    <div className={classes.root}>
      <CircularProgress size={100} />
    </div>
  );
}

export default withStyles(styles)(FullscreenLoader);
