import React, { useState, useEffect } from 'react';
import { Auth }       from 'aws-amplify';
import { connect }    from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { validateForm } from '../../../modules/validate-form';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/AuthButton';
import Input      from '../../../components/AuthInput';

import styles from './styles';

const NewPassword = (props) => {
  const { classes, cognitoUser } = props;

  const [password, setPassword] = useState({value: '', valid: true})
  const [passwordMatch, setPasswordMatch] = useState({value: '', valid: true})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!cognitoUser) props.history.replace('/');
  }, [cognitoUser, props.history])

  async function handleNewPassword(e) {
    e.preventDefault();

    const formObject = { password };
    const formValid = validateForm(formObject);

    if (formValid.invalid.length > 0) {
      return handleError(new Error(formValid.invalid[0].message))
    }

    setLoading(true)
    try {
      await Auth.cleanCachedItems();
      await Auth.completeNewPassword(cognitoUser, password.value);
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      props.history.replace('/dashboard');
    }
    catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm onSubmit={handleNewPassword}>

      <AuthHeader />

      <div className={classes.helpText}>
        <Typography variant="body2" component={"p"} style={{ color: "white" }}>
          Please set your new password before continuing
        </Typography>
      </div>

      <Input
        id='password'
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setPassword({value: e.target.value, valid: true})}
        value={password.value}
        error={!password.valid} />
      <Input
        id='password-match'
        marginBottom={24}
        label='Confirm Password'
        type='password'
        onChange={e => setPasswordMatch({value: e.target.value, valid: true})}
        value={passwordMatch.value}
        error={!passwordMatch.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        loading={loading}
        fullWidth={true}
        onClick={handleNewPassword}
        size="large"
        text={'Set Password'}
        variant="contained"
      />

    </AuthForm>
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(withStyles(styles)(NewPassword));