export const SEARCH_FOR_INBOX = 'SEARCH_FOR_INBOX';
export const UPDATE_INBOX_FIELD = 'UPDATE_INBOX_FIELD';

export const INIT = {
  searchInput: {
    value: '',
    valid: true
  },
  lastSearched: '',
  emails: []
};

export default function(state = INIT, { type, payload }) {

  switch(type) {
    case SEARCH_FOR_INBOX:
    case UPDATE_INBOX_FIELD:
      return payload;
    default:
      return state;
  }
  
}