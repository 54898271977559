import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import { setUser } from './redux/actions/user';

import { history } from './redux/config';

import FullscreenLoader from './components/FullscreenLoader';

import UserStack from './containers/User';
import AuthStack from './containers/Auth';

/**
 * A HOC that routes a user to the appropriate navigation stack based on the authorizing function.
 * Must have access to the history.
 * @param {*} props 
 */

const Authorizer = props => {
  const { user, setUser } = props;

  useEffect(() => {
    authenticate();
  }, [history.location.pathname]);

  async function authenticate() {
    try {
      let userInfo = await Auth.currentUserInfo();
      
      setUser(userInfo);
    } catch (error) {
      setUser({});
      await Auth.signOut();
      console.log(error);
    }
  }

  let routes;

  if (JSON.stringify(user) === '{}') {
    routes = <AuthStack {...props} />;
  }
  else if (user && user.username) {
    routes = <UserStack {...props} />;
  }
  else {
    routes = <FullscreenLoader />;
  }

  return routes;
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { setUser })(Authorizer);