import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import styles from './styles';

const AuthInput = (props) => {
  const { classes, marginBottom, ...rest } = props;

  return (
    // set defaults for text fields app-wide
    <TextField
      ref={props.textref}
      type='text'
      fullWidth={true}
      style={marginBottom ? {marginBottom} : {}}
      value={props.value}
      onChange={props.onChange}
      error={!!props.error}
      InputProps={{ classes: { root: classes.input, underline: classes.underline, disabled: classes.inputDisabled } }}
      InputLabelProps={{
        classes: {
          disabled: classes.labelDisabled
        },
        FormLabelClasses: { root: classes.label, disabled: classes.labelDisabled }
      }}
      
      {...rest}
    />
  );
}

export default withStyles(styles)(AuthInput);
