import validator from 'validator';

export const validateField = (field, value, match) => {
  let valid = true;
  let message = 'Required';

  if (value.length === 0) {
    message = field.replace(/_|-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    let areOrIs = message.charAt(message.length-1) === "s" ? "are" : "is"

    return {
      valid: false,
      message: `${message} ${areOrIs} required`,
    };
  }

  switch (field) {
    case 'email':
      valid = validator.isEmail(value);
      message = 'Enter a valid email address';
      break;
    case 'newPassword':
      valid = validator.matches(value, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,255}$/); // Password matching expression. Password must be at least 8 characters, no more than 255 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit.
      message = 'Must be at least 8 digits and contain at least 1 uppercase letter, 1 lowercase letter, and one numeric digit';
      break;
    case 'passwordConfirm':
      valid = value === match;
      message = 'Passwords must match';
      break;
    case 'code':
      valid = value.length > 0;
      message = "Code field is required";
      break;
    default:
      valid = true;
      break;
  }

  return {
    valid,
    message,
  };
};
