import React from 'react';
import {
  Route,
  Router,
  Switch
} from 'react-router-dom';

import Authorizer from './Authorizer';

import { history } from './redux/config';

// import ConnectedModal from './components/ConnectedModal';
import Snackbar from './components/Snackbar';

const _Router = props => {
  return (
    <React.Fragment>
      
      <Snackbar />
      {/* <ConnectedModal /> */}

      <Router history={history}>
        <Switch>
          {/* The extra routes are only there so I can access the match.params inside my Navigation component inside the UserStack */}
          <Route path="/inbox/:email/:id" component={Authorizer} />
          <Route path="/inbox/:email" component={Authorizer} />
          <Route path="/" component={Authorizer} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}



export default _Router;
