import { combineReducers } from 'redux';

// Import reducers here
import email    from './email';
import inbox    from './inbox';
import snackbar from './snackbar';
import user     from './user';

const rootReducer = combineReducers({
  email,
  inbox,
  snackbar,
  user
});

export default rootReducer;
