const styles = theme => ({
    input: {
        color: 'white',
    },
    inputDisabled: {
        color: theme.palette.primary.main,
        opacity: 0.8,
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid white'
        },
        '&:hover': {
            borderBottom: `1px solid ${theme.palette.primary.main}`
        }
    },
    label: {
        color: 'white',
    },
    labelDisabled: {
        color: `${theme.palette.primary.main} !important`,
        opacity: 0.8
    }
});

export default styles;