export const emailParams = `
  pk
  sk
  headers
  subject
  from {
    address
    name
  }
  to {
    address
    name
  }
  cc {
    address
    name
  }
  bcc {
    address
    name
  }
  date
  messageId
  inReplyTo
  replyTo
  references
  html
  text
  textAsHtml
  attachments {
    filename
    contentType
    size
    related
    location {
      bucket
      key
    }
  }
`;