import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import styles from './styles';

const Inbox = props => {
  const { classes, searchInput, emails } = props;

  function formatDate(date) {
    let diff = new Date() - new Date(date);

    return Math.round(diff / 60000);
  }

  function handleRowClick(id) {
    props.history.push(`/inbox/${searchInput.value}/${id}`);
  }

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Received</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            emails.map(({ from, subject, date, sk }, index) => (
              <TableRow
                hover
                key={`inbox-row-${index}`}
                onClick={e => handleRowClick(sk)}
              >
                <TableCell>{from.map(({ address }) => address).join(', ')}</TableCell>
                <TableCell>{subject}</TableCell>
                <TableCell>{formatDate(date)} minutes ago</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

const mapStateToProps = ({ inbox }) => ({ ...inbox });

export default connect(mapStateToProps)(withStyles(styles)(Inbox));
