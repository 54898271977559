import { DARK_GREY } from '../../theme';

const drawerWidth = 200;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    navIconHide: {
        borderRadius: 0,
        [theme.breakpoints.up('sm')]: {
            width: 90
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        alignItems: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: 0,
        paddingLeft: 0
    },
    form: {
        display: 'flex',
        flex: '1'
    },
    input: {
        justifyContent: 'center',
        paddingLeft: theme.spacing.unit * 4,
        paddingRight: theme.spacing.unit * 4,
        flex: 1,
        fontSize: 22
    },
    iconButton: {
        backgroundImage: 'linear-gradient(180deg, #ff8f00 0%, #ff6900 100%);',
        borderRadius: 0,
        color: 'white',
        width: 48,
        [theme.breakpoints.up('sm')]: {
            width: 90
        }
    },
    logo: {
        alignSelf: 'center',
        paddingTop: 36,
        paddingBottom: 36
    },
    drawerDocked: {
        height: '100%'
    },
    drawerPaper: {
        backgroundColor: DARK_GREY,
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        paddingTop: 0,
        overflow: 'scroll'
    },
    list: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    listItemText: {
        color: 'white',
        fontSize: 22,
        fontFamily: 'Avenir Next'
    },
    spacer: {
        flexGrow: 1
    },
    drawer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
});

export default styles;