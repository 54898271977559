// AWS Project Constants
const awsmobile = {
  Auth: {
    identityPoolId: 'us-west-2:c0fdfb50-278c-4d4d-bee5-970653bebcb2',
    userPoolId: 'us-west-2_FQz9vGsF3',
    userPoolWebClientId: '56e548i9hq6orhstfu89ro85uh',
    region: 'us-west-2',
  },
  Storage: {
    AWSS3: {
      bucket: 'fyremail-emails-development',
      region: 'us-west-2',
    },
  },
  aws_appsync_graphqlEndpoint: 'https://bd4ciebtxvgb3ksxp3sqlld4gq.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsmobile;
