import React, { useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect }                 from 'react-redux'
import { setSnackbar }             from '../../redux/actions/snackbar'
import { withStyles }              from '@material-ui/core/styles';
import translateAwsError           from '../../modules/translate-aws-error';

import Paper from '@material-ui/core/Paper';

import Confirm        from './Confirm';
import ForgotPassword from './ForgotPassword';
import Login          from './Login';
import NewPassword    from './NewPassword';
// import Register       from './Register';
import ResetPassword  from './ResetPassword';

import styles from './styles';

const Auth = props => {
    const { classes, setSnackbar } = props;

    const [cognitoUser, setCognitoUser] = useState(null);

    function handleError(error) {
        if (translateAwsError(error.code)) {
            error.message = translateAwsError(error.code);
        }

        setSnackbar(error.message);
    }

    /** Initial route */
    const _Login = (props) => <Login onError={handleError} setCognitoUser={setCognitoUser} {...props} />;

    /** Route used after registering, or if logging in and account is unregistered */
    const _Confirm = (props) => <Confirm onError={handleError} {...props} />;
    
    /** Register a new user */
    // const _Register = (props) => <Register onError={handleError} {...props} />;

    /** User that logged in required password change */
    const _NewPassword = (props) => <NewPassword cognitoUser={cognitoUser} onError={handleError} {...props} />;

    /** Send reset email */
    const _ForgotPassword = (props) => <ForgotPassword onError={handleError} {...props} />;

    /** Reset password from reset email */
    const _ResetPassword = (props) => <ResetPassword onError={handleError} {...props} />;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0}>
                <Switch>
                    <Route exact path="/confirm" component={_Confirm} />
                    {/* <Route exact path="/register" component={_Register} /> */}
                    <Route exact path="/new-password" component={_NewPassword} />
                    <Route exact path="/forgot-password" component={_ForgotPassword} />
                    <Route exact path="/reset-password" component={_ResetPassword} />
                    <Route exact path="/" component={_Login} />
                    <Route exact path="/*" component={() => <Redirect to='/' />} />
                </Switch>
            </Paper>
        </div>
    );
}

const actions = { setSnackbar }
export default connect(null, actions)(withStyles(styles)(Auth));