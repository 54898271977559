const styles = theme => ({
    root: {

    },

    helpText: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
    }
});

export default styles;