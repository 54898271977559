import React, { useState, useEffect } from 'react';
import { Auth }             from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';
import queryString          from 'query-string'

import { validateForm }  from '../../../modules/validate-form';

import AuthForm   from '../../../components/AuthForm';
import AuthHeader from '../../../components/AuthHeader';
import Button     from '../../../components/AuthButton';
import Input      from '../../../components/AuthInput';

import styles from './styles';

const ResetPassword = (props) => {
  const [email] = useState(queryString.parse(props.location.search).email)
  const [code, setCode] = useState({value: '', valid: true})
  const [password, setPassword] = useState({value: '', valid: true})
  const [passwordMatch, setPasswordMatch] = useState({value: '', valid: true})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!email) props.history.replace('/forgot-password');
  }, [email, props.history])

  async function handleResetPassword(e) {
    e.preventDefault();

    const formObject = { code, password, passwordMatch };
    const formValid = validateForm(formObject);

    if (formValid.invalid.length > 0) {
      return handleError(new Error(formValid.invalid[0].message))
    }

    setLoading(true)
    try {
      await Auth.forgotPasswordSubmit(email, code.value, password.value)
      // setLoading(false); /** Since we are already changing pages this causes memory Leaks  */
      props.history.replace('/')
    }
    catch(error) {
      setLoading(false);
      handleError(error);
    }
  }

  function handleError(error) {
    if (typeof props.onError === 'function') {
      props.onError(error);
    }
  }

  return(
    <AuthForm onSubmit={handleResetPassword}>

      <AuthHeader />

      <Input
        id='email'
        disabled={true}
        marginBottom={16}
        label='Email Address'
        value={email} />
      <Input
        id='code'
        marginBottom={16}
        label='Code'
        onChange={e => setCode({value: e.target.value, valid: true})}
        value={code.value}
        error={!code.valid} />
      <Input
        id='password'
        marginBottom={16}
        label='Password'
        type='password'
        onChange={e => setPassword({value: e.target.value, valid: true})}
        value={password.value}
        error={!password.valid} />
      <Input
        id='passwordMatch'
        marginBottom={16}
        label='Confirm Password'
        type='password'
        onChange={e => setPasswordMatch({value: e.target.value, valid: true})}
        value={passwordMatch.value}
        error={!passwordMatch.valid} />

      <input type="submit" style={{ display: 'none' }} />

      <Button
        color="primary"
        loading={loading}
        fullWidth={true}
        onClick={handleResetPassword}
        size="large"
        text={'Reset Password'}
        variant="contained"
      />
      
    </AuthForm>
  );
}

export default withStyles(styles)(ResetPassword);