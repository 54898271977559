const styles = theme => ({
  info: {
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.secondary.main,
      marginLeft: -theme.spacing.unit * 3,
      marginRight: -theme.spacing.unit * 3,
      padding: theme.spacing.unit * 3
  },
  html: {
    marginTop: theme.spacing.unit * 3
  },
  attachments: {
    marginTop: theme.spacing.unit * 3
  },
  link: {
    color: 'darkblue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

export default styles;