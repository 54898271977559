const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export default styles;