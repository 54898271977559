import { API, Auth, graphqlOperation } from 'aws-amplify';
import S3 from 'aws-sdk/clients/s3';

import { getEmail as _getEmail } from '../../graphql/queries/Email.query';

import { GET_EMAIL } from '../reducers/email';

export const getEmail = (pk, sk) => async (dispatch, getState) => {
  let graphQLResponse = await API.graphql(graphqlOperation(_getEmail, { pk, sk }));

  let creds = await Auth.currentCredentials();
  let s3 = new S3({ credentials: Auth.essentialCredentials(creds), region: 'us-west-2' });

  const urls = await Promise.all(graphQLResponse.data.getEmail.attachments.map(attachment => {
    // console.log(attachment);
    return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', { Key: attachment.location.key, Bucket: attachment.location.bucket, Expires: 300 }, (err, url) => {
        if (err) reject(err);
  
        resolve({
          filename: attachment.filename,
          url,
          size: attachment.size
        });
      });
    });
  }));

  graphQLResponse.data.getEmail.attachments = urls;

  dispatch({
    type: GET_EMAIL,
    // payload: {}
    payload: graphQLResponse.data.getEmail
  });
};